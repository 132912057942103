<template>
  <div class="full-width-container ">
    <div class="edit-button-wrapper">
      <button v-if="authStore.isLoggedIn && memorialProfile.owner === authStore.currentUserId" class="floating-edit-button" @click="navigateToEditPage">
        <v-icon>
          <img class="edit-icon" alt="" src="@/assets/images/icon-edit-white-16x16.svg">
        </v-icon>
        <span>Edytuj profil</span>
      </button>
    </div>
  </div>

  <div class="scroll-container">
    <CoverPage :profile-data="memorialProfile" @scroll-to-second-page="handleScrollToPage"/>
    <EducationPage
      v-for="educationItem in (memorialProfile.sections || []).find(section => section.key === 'education')?.items || []"
      :key="educationItem.id"
      :education-data="educationItem"
    />
    <ImportantEventsPage
      v-for="eventItem in (memorialProfile.sections || []).find(section => section.key === 'important_events')?.items || []"
      :key="eventItem.id"
      :event-data="eventItem"
    />
    <WorkPage
      v-for="workItem in (memorialProfile.sections || []).find(section => section.key === 'work')?.items || []"
      :key="workItem.id"
      :work-data="workItem"
    />
    <AchievementsPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'achievements')?.items?.length > 0"
      :achievement-items="(memorialProfile.sections || []).find(section => section.key === 'achievements')?.items || []"
    />
    <FamilyPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'family')?.items?.length > 0"
      :family-data="(memorialProfile.sections || []).find(section => section.key === 'family')?.items || []"
    />
    <HobbiesPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'interests')?.items.length > 0"
      :hobby-items="(memorialProfile.sections || []).find(section => section.key === 'interests')?.items || []"
    />
    <AdditionalDescriptionPage
      v-for="additionalDescriptionItem in (memorialProfile.sections || []).find(section => section.key === 'additional_description')?.items || []"
      :key="additionalDescriptionItem.id"
      :additional-description-data="additionalDescriptionItem"
    />
    <GalleryPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'gallery')?.items?.length > 0"
      :gallery-data="(memorialProfile.sections || []).find(section => section.key === 'gallery')"
    />
    <FinalPage :profile-data="memorialProfile" @scroll-to-top="handleScrollToTop"/>
  </div>
</template>

<script>
import CoverPage from '@/views/MemorialProfilePages/CoverPage.vue';
import EducationPage from '@/views/MemorialProfilePages/EducationPage.vue';
import AchievementsPage from '@/views/MemorialProfilePages/AchievementsPage.vue';
import WorkPage from '@/views/MemorialProfilePages/WorkPage.vue';
import FinalPage from '@/views/MemorialProfilePages/FinalPage.vue';
import AdditionalDescriptionPage from '@/views/MemorialProfilePages/AdditionalDescriptionPage.vue';
import FamilyPage from '@/views/MemorialProfilePages/FamilyPage.vue';
import HobbiesPage from '@/views/MemorialProfilePages/HobbiesPage.vue';
import ImportantEventsPage from '@/views/MemorialProfilePages/ImportantEventsPage.vue';
import GalleryPage from '@/views/MemorialProfilePages/GalleryPage.vue';
import {useAuthStore} from "@/stores/auth";
import {useMemorialProfileStore} from "@/stores/memorial-profile";

export default {
  components: {
    CoverPage,
    EducationPage,
    FamilyPage,
    FinalPage,
    AdditionalDescriptionPage,
    WorkPage,
    AchievementsPage,
    HobbiesPage,
    ImportantEventsPage,
    GalleryPage,
  },
  props: {
    slotNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    const authStore = useAuthStore();

    return {
      authStore,
      memorialProfile: {
        title: '',
        description: '',
      },
      apiUrl: process.env.VUE_APP_API_URL,
      error: null,
    };
  },
  mounted() {
    this.fetchMemorialProfile();
    this.setupScrollSnap();
  },
  methods: {
    async fetchMemorialProfile() {
      try {
        const store = useMemorialProfileStore();
        this.memorialProfile = store._memorialProfile;

        if (!this.memorialProfile.sections) {
          this.memorialProfile.sections = [];
        }

        if (!this.memorialProfile.sections.find(section => section.key === 'work')) {
          this.memorialProfile.sections.push({ category: 'work', items: [] });
        }
      } catch (error) {
        console.error(error);
      }
    },
    setupScrollSnap() {
      const scrollContainer = document.querySelector('.scroll-container');
      scrollContainer.style.scrollSnapType = 'y mandatory';
      scrollContainer.style.overflowY = 'scroll';
      scrollContainer.style.height = 'calc(100vh - 64px)';

      Array.from(scrollContainer.children).forEach(child => {
        child.style.scrollSnapAlign = 'start';
      });
    },
    handleScrollToTop() {
      const scrollContainer = document.querySelector('.scroll-container');
      scrollContainer.scrollTo({top: 0, behavior: 'smooth'});
    },
    handleScrollToPage(index) {
      const pages = document.querySelectorAll('.profile-page-container-wrapper');

      if (pages.length > index) {
        pages[index].scrollIntoView({behavior: 'smooth'});
      }
    },
    navigateToEditPage() {
      if (this.authStore.isLoggedIn && this.memorialProfile.owner === this.authStore.currentUserId) {
        this.$router.push(`/profil-pamieci/${this.slotNumber}/edytuj`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  height: 100%; /* Adjusted for better responsiveness */
  width: 100%; /* Adjusted for better responsiveness */
  overflow-y: scroll; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: y mandatory; /* Enable full section scroll snapping */
  background-image:
      url('../assets/images/leaves-top-left.png'),
      url('../assets/images/leaves-bottom-left.png'),
      url('../assets/images/leaves-top-right.png'),
      url('../assets/images/leaves-bottom-right.png');
  background-position: top left, bottom left, top right, bottom right;
  background-repeat: no-repeat;
  background-size: 30%;

  @media (min-width: 768px) {
    background-size: auto;
  }
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container > * {
  scroll-snap-align: start; /* Align children to the start */
}

.full-width-container {
  width: calc(100vw - 32px);
  display: flex;
  justify-content: center;
  position: relative;
}

.edit-button-wrapper {
  position: fixed;
  top: 74px;
  z-index: 100;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
}

.floating-edit-button {
  background-color: #2A4F3C;
  border: none;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 0.875rem;
  padding: 10px 12px 10px 10px;
  font-weight: 500;
  margin-right: 8px;
  letter-spacing: 0.5px;

  @media (min-width: 768px) {
    margin-right: 0;
  }

  .edit-icon {
    width: 18px;
    height: 18px;
  }
}
</style>