<template>
  <v-container class="thank-you-page-container">
    <v-card class="thank-you-page" outlined>
      <v-card-title class="thank-you-header">Dziękujemy za zakup!</v-card-title>

      <p class="thank-you-message bold">
        Twoje zamówienie zostało złożone pomyślnie.<br>
        Wkrótce na podany podczas zakupu adres e-mail otrzymasz potwierdzenie.<br>
      </p>

        <v-icon class="divider-icon-wrapper">
          <img class="divider-icon" alt="" src="@/assets/images/icon-star-divider-80x8.svg">
        </v-icon>

      <p class="thank-you-message">Jeśli to Twój pierwszy zakup, otrzymasz również dane do zalogowania się na&nbsp;swoje&nbsp;konto.</p>

      <img class="thank-you-image" src="@/assets/images/logo-sign.svg" alt="Logo">

      <button @click="navigateToLogin" class="login-button">Przejdź do logowania</button>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ThankYouPage',
  methods: {
    navigateToLogin() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped>
.thank-you-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  @media (min-width: 768px) {
    padding: 40px;
  }

  @media (min-width: 1400px) {
    height: calc(100% - 120px);
    max-width: 50%;
  }
}

.thank-you-page {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: "Source Serif 4", serif;
  gap: 40px;

  @media (min-width: 768px) {
    padding: 40px;
  }
}

.thank-you-header {
  font-family: 'Source Serif 4', serif;
  font-weight: 600;
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
}

.thank-you-message {
  font-family: 'Source Serif 4', serif;
  font-size: 1rem;
  text-align: center;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  &.bold {
    font-weight: 600;
  }
}

.thank-you-image {
  max-width: 100%;
  margin: 20px 0;
  width: 120px;
  height: auto;
}

.login-button {
  background-color: #A28546;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.login-button:hover {
  background-color: #8a6d3b;
}

</style>
