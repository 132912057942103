<template>
  <div class="step-content achievements-category">
    <div class="memorial-profile-add-edit-form-category-header" @click="toggleShowSection">
      <h2 class="memorial-profile-add-edit-form-category-name">Osiągnięcia</h2>

      <button class="section-toggle">
        <span class="section-toggle-label">{{ isSectionOpen ? 'Zwiń sekcję' : 'Rozwiń sekcję' }}</span>

        <v-icon>{{ isSectionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </button>
    </div>

    <div v-if="isSectionOpen">
      <div class="horizontal-separator"></div>

      <p class="memorial-profile-add-edit-form-category-description">Kategoria osiągnięcia śłuży do opisania sukceców z
        życia, zarówno tych dużch jak i małych. </p>

      <div class="horizontal-separator"></div>

      <div v-for="(sectionItem, index) in category?.items" :key="sectionItem.id">
        <div class="memorial-profile-add-edit-form-category-item-header-wrapper">
          <p class="memorial-profile-add-edit-form-category-item-header">Osiągnięcie - wpis nr {{ index + 1 }}</p>

          <v-btn
              class="memorial-profile-add-edit-form-category-delete-button"
              prepend-icon="mdi-delete-outline"
              @click="removeCategoryItem(sectionItem.id)"
          >Usuń
          </v-btn>
        </div>

        <p class="memorial-profile-add-edit-form-category-paragraph">Dodaj zdjęcie nawiązujące do kategorii
          (opcjonalnie)</p>

        <DragAndDropField
            :photo-url="sectionItem.photoUrl"
            :is-upload-error="isImageUploadError"
            @files-changed="handleFilesChange($event, sectionItem?.id)"
            @image-deleted="handleDeleteImage($event, sectionItem?.id)"
        />

        <v-text-field v-model="sectionItem.name" label="Nazwa osiągnięcia"></v-text-field>

        <div class="dates-inline-wrapper">
          <v-text-field
              v-model="sectionItem.day"
              :rules="dayRules"
              label="Dzień"
              @change="v$.dayErrors?.$touch"
          />
          <v-text-field
              v-model="sectionItem.month"
              :rules="monthRules"
              label="Miesiąc"
              @change="v$.monthErrors?.$touch"
          />
          <v-text-field
              v-model="sectionItem.year"
              :rules="yearRules"
              label="Rok"
              @change="v$.yearErrors?.$touch"
          >
            <template #label>
              <span>Rok</span>
            </template>
          </v-text-field>
        </div>

        <v-textarea v-model="sectionItem.description" label="Opis"/>
      </div>
      <v-btn
          append-icon="mdi-plus"
          class="memorial-profile-add-edit-form-category-add-item-button"
          @click="addCategoryItem"
      >Dodaj kolejne osiągnięcie
      </v-btn>

      <div class="horizontal-separator"></div>

      <div class="memorial-profile-add-edit-form-category-footer-buttons">
        <v-btn
            class="memorial-profile-add-edit-form-category-delete-button"
            prepend-icon="mdi-delete-outline"
            @click="removeSection"
        >Usuń sekcję
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import DragAndDropField from "@/components/DragAndDropField.vue";
import { useMemorialProfileStore } from "@/stores/memorial-profile";
import {computed} from "vue";

export default {
  name: 'AchievementsCategory',
  components: { DragAndDropField },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  emits: ['section-updated'],
  setup(props) {
    const v$ = useVuelidate();
    const store = useMemorialProfileStore();
    const isSectionOpen = computed({
      get() {
        const section = store._memorialProfile?.sections?.find(section => section.id === props.category.id);
        return section?.isSectionOpen || false;
      },
      set(value) {
        store.updateSectionOpenState(props.category.id, value);
      }
    });

    return { v$, store, isSectionOpen };
  },
  data() {
    return {
      categoryName: 'achievements',
      isImageUploadError: false,
    };
  },
  computed: {
    dayRules() {
      return [
        v => !v || (v >= 1 && v <= 31) || 'Dzień musi być liczbą pomiędzy 1 a 31.',
      ];
    },
    monthRules() {
      return [
        v => !v || (v >= 1 && v <= 12) || 'Miesiąc musi być liczbą pomiędzy 1 a 12.',
      ];
    },
    yearRules() {
      return [
        v => !v || (v.length === 4) || 'Rok musi składać się z 4 cyfr.',
        v => !v || !isNaN(v) || 'Rok musi być liczbą.',
      ];
    },
  },
  methods: {
    toggleShowSection() {
      this.isSectionOpen = !this.isSectionOpen;
    },
    addCategoryItem() {
      if (!this.category) {
        return;
      }
      this.store.addSectionItemState(this.categoryName);
      this.$emit('section-updated');
    },
    removeSection() {
      this.store.removeSectionState(this.category.id);
      this.$emit('section-updated');
    },
    removeCategoryItem(itemId) {
      this.store.removeSectionItemState(this.category.id, itemId);
      this.$emit('section-updated');
    },
    handleFilesChange({ files }, sectionItemId) {
      if (!files?.length) {
        return;
      }

      const file = files[0];

      this.store.uploadImage({ file, categoryName: this.categoryName, sectionItemId })
        .then(() => {
          this.isImageUploadError = false;
        })
        .catch(() => {
          this.isImageUploadError = true;
        });
    },
    handleDeleteImage({ photoUrl }, sectionItemId) {
      this.store.deleteImage({ photoUrl, categoryName: this.categoryName, sectionItemId });
    },
  },
}
</script>

<style scoped>
</style>
