// filename: src/views/NotFoundPage.vue
<template>
  <v-container class="contact-page content">
    <h1>404 - Nie znaleziono strony</h1>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style scoped lang="scss">
/* Your CSS styles for ContactPage here */
</style>
